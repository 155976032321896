import { Box } from "@mui/material";
import Head from "next/head";
import React from "react";
import { getContentReturnObject } from "../../pages/api/content/[shortId]";
import Alert from "../Alert";

interface Props {
  children: React.ReactNode;
  projectInfo?: getContentReturnObject;
}

// Layout to be used on preview pages only. No Auth.
const PublicLayout = ({ children, projectInfo }: Props) => {
  const thumbnailUrl = getThumbnailUrl(projectInfo);
  return (
    <>
      <Alert />
      <Head>
        <link rel="icon" href={thumbnailUrl}></link>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={projectInfo?.description} />
        <meta property="og:type" content="Web application" />
        <meta
          property="og:title"
          content={projectInfo && projectInfo.name ? projectInfo?.name : "Submarine.me"}
        />
        <meta
          property="og:description"
          content={
            projectInfo && projectInfo.name
              ? projectInfo?.description
              : "Locked content powered by Submarine.me"
          }
        />

        <meta
          property="og:image"
          content={
            projectInfo?.customizations?.socialImage
              ? `${process.env.NEXT_PUBLIC_R2_URL}/${projectInfo.customizations.socialImage}`
              : "https://app.submarine.me/submarine.png"
          }
        />
        <title>{projectInfo && projectInfo.name ? projectInfo.name : "Submarine.me"}</title>
      </Head>
      {children}
    </>
  );
};

export default PublicLayout;

const getThumbnailUrl = (projectInfo) => {
  const gatewaySubdomain = projectInfo?.gatewayUrl;
  const GATEWAY_URL = `https://${gatewaySubdomain}.${process.env.NEXT_PUBLIC_GATEWAY_ROOT}.cloud`;
  let thumbnailUrl;
  const thumbnail = projectInfo?.customizations?.thumbnail;
  if (!thumbnail) {
    thumbnailUrl = "/submarine.png";
  } else if (thumbnail.includes("user/")) {
    thumbnailUrl = `${process.env.NEXT_PUBLIC_R2_URL}/${thumbnail}`;
  } else {
    thumbnailUrl = `${GATEWAY_URL}/ipfs/${thumbnail}`;
  }
  return thumbnailUrl;
};
